import * as React from "react"
import HorseButton from "../../../components/horse-button"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const Horse3 = () => (
  <Layout bodyClass="archiveOrg horse-3">
    <Seo title="Horse 3" />
    <div class="poem">
      <h1>Horse-3</h1>
      <p>
        This is the third horse file
        <br />
        I made it to test <br />
        loading pages
        <br />
        from graphql into
        <br />
        <br />
        the horse button
        <br />
        <br />
        Then I never turned it
        <br />
        into a proper horse page
        <br />
        <br />
        So here stands horse 3 <br />
        alone and horseless <br />
        but still <br />
        in the array.
      </p>
      <HorseButton />
    </div>
  </Layout>
)

export default Horse3
